<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <div class="timetable">
    <n-loader :loading="$var('load')" />

    <create-schedule-modal :show.sync="show" :company-id="companyId" @refreshSchedule="fetchSchedule"></create-schedule-modal>
    <div class="timetable__header">
      <n-button @click="openModalCreateSchedule">Создать график</n-button>
    </div>

    <div class="timetable__table-block">
      <n-table :columns="timetableHeaderTable" :data="schedule">
        
        <template #headerCell="{ column }">
          <div class="table-header">
            {{ column.title }}
            <div v-if="column.title === 'Тип'" class="tooltip">
              <span class="tooltip-element" type="button">
                !
              </span>

              <ul class="tooltip-info">
                <li>
                  <span>Календарный расчет</span> - Расписание рассчитывается автоматически на основе календаря Prosper Pay. Никакие дополнительные данные не требуются.
                </li>
                <li>
                  <span>Фиксированное значение</span> - Расписание основано на фиксированном количестве рабочих дней, которое необходимо указать в поле "Кол-во рабочих дней".
                </li>
                <li>
                  <span>Рассчитать автоматически</span>  - Расписание рассчитывается автоматически на основе workdays из 1С
                </li>
              </ul>
            </div>
          
          </div>
        </template>

        <template #scheduleType="{ item }">
          {{ item.scheduleType }}
        </template>

        <template #workDayConst="{ item }">
          <template v-if="item.calculationType.value === 'CONST' && item.isChanges">
            <n-input
              v-model="item.workDayConst"
              type="number"
              placeholder="Введите количество рабочих дней"
              class="stat-select input"
            />
          </template>

          <template v-else>
            {{ item.workDayConst }}
          </template>
        </template>

        <template #calculationType="{ item }">
          <template v-if="item.isChanges">
            <n-select
              class="stat-select"
              :data="calculationTypes" 
              :value.sync="item.calculationType" />
          </template>

          <template v-else>
            {{ item.calculationType.title }}
          </template>
        </template>

        <template #changeSchedule="{ item }">
          <template v-if="item.isChanges">
            <button @click="changeScheduls(item.id)">Сохранить</button>
          </template>

          <template v-else>
            <button @click="toggleChanges(item)">Изменить</button>
          </template>
        </template>
      </n-table>
    </div>
  </div>
</template>

<script>
import { timetableHeaderTable, calculationTypes } from './constants';
import mixin from '@mixins/index'

export default {
  name: 'CTimetable',
  mixins: [ mixin ],
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timetableHeaderTable,
      calculationTypes,
      schedule: [],
      isChanges: false,
      calculationType: {},
      show: false,
    }
  },
  mounted() {
    this.fetchSchedule()
  },
  methods: {
    fetchSchedule() {
      this.$var('load', true)

      $api.companies.getSchedule(this.companyId)
        .then((response) => {
          this.schedule = response.data.map((el) => {
          
            return { 
              ...el, 
              isChanges: false,
              workDayConst: el.workDayConst || 0,
              calculationType: this.calculationType = $n.find(this.calculationTypes, (e) => {
                if (e.value === el.calculationType) return e
              }),
            }
          });
          this.$var('load', false)
        })
        .catch((error) => {
          console.log(error.message);
          this.$var('load', false)
          $app.ui.notify('Ошибка при загрузке графиков, попробуйте позже.', 'danger')
        })
    },
    async changeScheduls(id) {
      const data = this.schedule.find((el) => el.id === id);
      const formatData = this.transformScheduleItem(data, this.companyId);

      $api.companies
        .changeSchedule(formatData, this.companyId)
        .then((response) => {
          if (response.status === 200) {
            return this.fetchSchedule();
          }
        })
        .then(() => {
          $app.ui.notify('Данные успешно обновлены.', 'success');
        })
        .catch((error) => {
          $app.ui.notify('Ошибка изменения, попробуйте позже.', 'danger');
        });
    },

    transformScheduleItem(item, companyId) {
      return {
        id: item.id,
        scheduleType: item.scheduleType,
        workDayConst: Number(item.workDayConst),
        calculationType: item.calculationType.value,
        companyId: Number(companyId),
      };
    },
    toggleChanges(item) {
      item.isChanges = !item.isChanges;
    },
    openModalCreateSchedule() {
      this.show = true
    },
  }
  
}
</script>

<style scoped src="./c-timetable.scss" lang="scss"></style>

