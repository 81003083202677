export default {
  computed: {
    userRoles() {
      const auth = JSON.parse(localStorage.getItem('auth'));
      const token = auth?.token;
      if (token) {
        const decodedToken = atob(token.split('.')[1])
        const tokenData = JSON.parse(decodedToken)
        return tokenData.authorities || [];
      }
    },
    hasRole() {
      return (role) => this.userRoles.includes(role);
    },
  },
};
