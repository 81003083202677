/* eslint-disable no-case-declarations */
export default () => ({
  api: {
    servers: {
      default: $env('server'),
    },
    then: (response) => {
      if (!response.data) {
        response.data = { content: null, message: null };
      }
      if (!response.data.content) {
        response.data.content = response.data.data
      }
      if (response.data.message) {
        $app.ui.notify(response.data.message, 'success')
      }
      return response
    },
    catch: (error) => {

      if (!error.response) {
        $app.ui.notify('Не удалось соединиться с сервером. Проверьте подключение к интернету.', 'danger');
        throw error;
      }

      switch (error.response?.status) {
      case 401:
        $app.auth.logout()
        break;
      case 422:
        let data = '';
        data = error.response?.data.data || error.response?.data.message

        let errors = ''
        if (Object.keys(data).length > 0) {
          $n.each(data, (item) => {
            errors += item
          })
        } else {
          data = error.response?.data.message
          errors = data || 'Время блокировки не должно быть менее часа'
        } 
        $app.ui.notify(errors, 'danger')
        break;
      case 409:
        const message = (error.response?.data.message === 'Request commission overlaps existing commission') ? 
          'Запрашиваемая комиссия дублирует существующую комиссию' : 
          'Конфликт данных. Попробуйте позже.';
        
        $app.ui.notify(message, 'danger');

        break;
      default:
        let messageDefault = '';  
        
        if (Object.keys( error.response?.data ).length > 0) {
          messageDefault = error.response?.data
          console.log(messageDefault);
          
          throw messageDefault;
        } else {
          messageDefault = 'Сервер недоступен'  
          $app.ui.notify(messageDefault, 'danger')
        }
      }

      throw error;
    },
  },
  router: {
    config: {
      base: $env('baseUrl'),
      mode: 'history',
    },
  },
  store: {
    saveDepth: 2,
  },
  auth: {
    loginRoute: 'login',
    authRoute: 'index',
    apiAuth: (token) => {
      $app.api.config({
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
    },
  },
  date: {
    formats: {
      date: 'DD.MM.YYYY',
      time: 'HH:mm',
      datetime: 'DD.MM.YYYY HH:mm',
    },
    default: 'datetime',
  },
  form: {
    input: (self, name, type, form) => {
      if (type === 'select') {
        return {
          name,
          value: self.$form.get(name, form),
          'update:value': (value) => self.$form.set(name, value, form),
          danger: self.$form.errors(name, form),
          text: self.$form.editable(form) === false,
        }
      }
      return {
        name,
        value: self.$form.get(name, form),
        input: (value) => self.$form.set(name, value, form),
        danger: self.$form.errors(name, form),
        text: self.$form.editable(form) === false,
      }
    },
    validations: {
      required(value) {
        return value.length > 0
      },
      email(value) {
        return value.indexOf('@') !== -1
      },
      phone(value) {
        return value.indexOf('+') !== -1
      },
    },
    messages: {
      required: 'Поле обязательно для заполнения',
      email: 'Поле должно быть email адресом',
      phone: 'Поле должно быть корректным телефонным номером',
      between: (name, params, value) => `Поле должно быть от ${params[0]} до ${params[1]}`,
    },
  },
  secure: {
    whiteList: { p: [], br: [], },
  },
  ui: {
    duration: {
      info: 3000,
      danger: 5000,
      success: 3000,
    },
  },
})
