export default [
  {
    path: "",
    component: () => import("layouts/App"),
    children: [
      {
        path: "",
        component: () => import("layouts/auth/Index"),
        children: [
          {
            path: "",
            name: "login",
            icon: "genderless",
            component: () => import("pages/login/Index"),
            meta: { roles: [] },
          },
        ],
      },
      {
        path: "",
        component: () => import("layouts/main/Index"),
        children: [
          {
            path: "/companies",
            name: "companies",
            title: "Компании",
            icon: "genderless",
            component: () => import("pages/companies/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_ADMIN_PANEL_VIEWER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/history-action",
            name: "history.action",
            title: "История действий",
            icon: "genderless",
            component: () => import("pages/history-action/HistoryActionPage"),
            meta: { roles: [ 'ROLE_SUPER_ADMIN' ] },
          },
          {
            path: "/support",
            name: "support",
            title: "Поддержка",
            icon: "genderless",
            component: () => import("pages/support/support"),
            meta: { roles: [ 'ROLE_APP_ADMIN', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/access-control",
            name: "access.access",
            title: "Управление доступами",
            icon: "genderless",
            component: () => import("pages/access-control-page/AccessControlPage"),
            meta: { roles: [ 'ROLE_SUPER_ADMIN' ] },
          },
          {
            path: "/excel",
            name: "excel",
            title: "Выгрузка",
            icon: "genderless",
            component: () => import("pages/excel/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_ADMIN_PANEL_VIEWER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/integration",
            name: "integration",
            title: "Интеграция",
            icon: "genderless",
            component: () => import("pages/integration/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/transaction-modifier",
            name: "transaction-modifier",
            title: "Редактирование транзакций",
            icon: "genderless",
            component: () => import("pages/transaction-modifier/Index"),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/companies/:companyId/departments/:id",
            name: "department",
            title: "Департамент",
            icon: "genderless",
            component: () => import("pages/department/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN', 'ROLE_ADMIN_PANEL_VIEWER' ] },
          },
          {
            path: "/companies/:companyId/filials/:id",
            name: "filials",
            title: "Филиалы",
            icon: "genderless",
            component: () => import("pages/filial/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN', 'ROLE_ADMIN_PANEL_VIEWER' ] },
          },
          {
            path: "/settings",
            name: "settings",
            title: "Переменные",
            icon: "genderless",
            component: () => import("pages/settings/Index"),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/company/:id",
            name: "company",
            title: "Компания",
            icon: "genderless",
            component: () => import("pages/company/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN', 'ROLE_ADMIN_PANEL_VIEWER' ] },
          },
          {
            path: "/stats",
            name: "stats",
            title: "Статистика приложения",
            icon: "genderless",
            component: () => import("pages/app-stats/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/applications",
            name: "applications",
            title: "Заявки тех поддержки",
            icon: "genderless",
            component: () => import("pages/applications/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN', 'ROLE_ADMIN_PANEL_VIEWER' ] },
          },
          {
            path: "/faqs",
            name: "faqs",
            title: "FAQ",
            icon: "genderless",
            component: () => import("pages/faqs/Index"),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/users",
            name: "users",
            title: "Пользователи",
            icon: "genderless",
            component: () => import("pages/users/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/users/:id",
            name: "user",
            title: "Пользователь",
            icon: "genderless",
            component: () => import("pages/user/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/password",
            name: "password",
            title: "Смена пароля",
            icon: "genderless",
            component: () => import("pages/password/Index"),
            meta: { roles: [ 'ROLE_SUPER_ADMIN' ] },
          },
          {
            path: "/promotions",
            name: "promotions",
            title: "Акции",
            icon: "genderless",
            component: () => import("pages/promotions/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/promotions/:id",
            name: "promotion",
            title: "Акция",
            icon: "genderless",
            component: () => import("pages/promotion/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/notifications",
            name: "notifications",
            title: "Уведомления",
            icon: "genderless",
            component: () => import("pages/notifications/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/mailing",
            name: "mailing",
            title: "Рассылка",
            icon: "genderless",
            component: () => import("pages/mailing/Index"),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
          {
            path: "/notifications/create",
            name: "notificationsCreate",
            title: "Создание уведомления",
            icon: "genderless",
            component: () => import("pages/notifications-create/Index"),
            meta: { roles: [ 'ROLE_MANAGER', 'ROLE_APP_ADMIN' ] },
          },
          { path: '/access-blocks', 
            name: 'access-blocks', 
            title: 'Блокировка', 
            icon: 'genderless', component: () => import('pages/access-blocks/Index'),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
          { path: '/access-blocks/create', 
            name: 'accessBlocksCreate', 
            title: 'Создание блокировки', 
            icon: 'genderless', component: () => import('pages/access-blocks-create/Index'),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
          { path: '/antifraud',
            name: 'antifraud',
            title: 'Доп проверка',
            icon: 'genderless', component: () => import('pages/antifraud/Index'),
            meta: { roles: [ 'ROLE_APP_ADMIN' ] },
          },
        ],
      },
    ],
  },
]
