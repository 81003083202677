<template>
  <div class="c-promo-add-users">
    <div class="department-window">
      <n-card class="department-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal" class="close-button">X</n-button>
        </div>
        <div>
          <div class="department-info">
            <div v-for="item in users" :key="item.id" class="user">
              <label class="user-label">
                <input v-model="selected" type="checkbox" class="user-check" :value="item">
                <span class="user-full-name">{{ item.fullName }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="button-save">
          <n-button color="primary" @click="save">Сохранить</n-button>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPromoAddUsers',
  props: {
    id: {
      type: Number,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
    branch: {
      type: Number,
      required: true,
    },
    priority: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      selected: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    async load() {
      this.$var('load', true)
      let totalPages = 5
      let currentPage = 0
      await $api.branches.getUsersList(this.companyId, this.branch).query('page=' + currentPage).then((response) => {
        totalPages = response.data.totalPages
      }).catch(() => {
        this.$var('load', false)
      })

      if (totalPages === 0) {
        $app.ui.notify('Список сотрудников этого отдела пуст', 'success')
        this.$var('load', false)
        this.closeModal()
      }

      while (currentPage < totalPages) {
        await $api.branches.getUsersList(this.companyId, this.branch).query('page=' + currentPage).then((response) => {
          $n.map(response.data.content, (e) => {
            this.users.push(e)
          })
          currentPage++
          this.$var('load', false)
        }, 1000)
      }
    },
    save() {
      this.$var('load', true)
      const users = $n.map(this.selected, (e) => {
        return e.userId
      })
      $api.promotions.assignToUsers(this.id, { companyId: this.companyId, usersIds: users, }).then(() => {
        this.closeModal()
        $app.ui.notify('Назначение акции началось', 'success')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-label {
  padding: 10px;
  margin-bottom: 15px;
  .user-check {
    width: 24px;
    height: 24px;
  }
  .user-full-name {
    font-size: 1.3em;
  }
}
.c-promo-add-users {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
    position: sticky;
    bottom: 0;
  }

  .department-card {
    ::v-deep .content {
      max-height: 90vh;
    }
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
    .close-button {
      position: absolute;
    }
  }
}
</style>
