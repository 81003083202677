<template>
  <div class="c-show-promo">
    <div class="faq-window">
      <n-card class="faq-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="faq-info">
            <n-items>
              <n-input title="Название акции" v-bind="$form.input('title')"></n-input>
              <c-textarea title="Описание администратору" v-bind="$form.input('description')"></c-textarea>
              <c-textarea title="Описание пользователю" v-bind="$form.input('userDescription')"></c-textarea>
              <n-input title="Минимальная комиссия" v-bind="$form.input('minCommission')"></n-input>
              <n-input title="Процент комиссии" v-bind="$form.input('percent')"></n-input>
              <n-input title="Количество дней сколько акция действует после выдачи" v-bind="$form.input('daysCount')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CShowPromo',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      content: '',
      status: {},
    }
  },
  created() {
    this.load()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      this.$var('load', true)
      $api.promotions.get(this.id).then((response) => {
        this.$form.init(response.data.content)
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)
      $api.promotions.edit(this.id, this.$form.get()).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-show-promo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 100px;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
