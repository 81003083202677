<template>
  <div class="c-show-application">
    <div class="application-window">
      <n-card class="application-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="application-info">
            <n-items>
              <n-input title="Телефон" :value="$form.get('phone')" text></n-input>
              <n-input title="Тема" :value="$form.get('title')" text></n-input>
              <n-input title="Содержание" :value="$form.get('content')" text></n-input>
              <n-select v-if="status.name !== 'finish'" title="Статус" :data="statuses" :value.sync="status"></n-select>
              <n-input v-else title="Статус" :value="status.title" text></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CShowApplication',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      vars: {
        minCommission: 'Минимальная комиссия',
        mrp: 'МРП',
      },
      statuses: [
        { name: 'new', title: 'Новая', },
        { name: 'progress', title: 'В обработке', },
        { name: 'finish', title: 'Завершена', },
      ],
      status: {},
    }
  },
  created() {
    this.load()
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    load() {
      console.log(this.id)
      this.$var('load', true)
      $api.application.get(this.id).then((response) => {
        this.$form.init(response.data.content)
        this.status = $n.find(this.statuses, (e) => {
          if (e.title === response.data.content.status) {
            return e
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      this.$var('load', true)
      const data = {
        status: this.status.name,
      }
      $api.application.edit(this.id, data).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-show-application {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
