import BaseRouter from 'nast-router'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { sync, } from 'vuex-router-sync'
/**
 *
 */
export default class NastRouter extends BaseRouter {

  // eslint-disable-next-line require-jsdoc
  router() {
    if (!this._router) {
      this._vue.use(Meta, {
        keyName: 'html',
        attribute: 'dv-meta',
        ssrAttribute: 'dv-meta-server-rendered',
        tagIDKeyName: 'mid',
      })

      this._vue.use(VueRouter)

      this._router = new VueRouter({
        ...this._config('config'),
        fallback: false,
        routes: this._routes,
      })

      this._router.beforeEach((to, from, next) => {
        if ('login' === to.name) {
          return next();
        }

        if (this._checkAccess(to)) {
          next();
        } else {
          next({ name: 'login' });
        }
      });
      sync(this._store, this._router)
    }

    return this._router
  }

  /**
     * Проверяет, есть ли у пользователя доступ к маршруту.
     * @param {Object} route - Объект маршрута.
     * @return {Boolean} - Доступ разрешен или нет.
     */
  _checkAccess(route) {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const token = auth?.token;

    if (token) {
      try {
        const decodedToken = atob(token.split('.')[1])
        const tokenData = JSON.parse(decodedToken)
        const userRoles = tokenData.authorities || [];
        if (route.meta?.roles && route.meta.roles.length > 0) {
          return route.meta.roles.some((role) => userRoles.includes(role));
        }

        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }
}
