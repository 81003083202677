<template>
  <div class="c-create-promo">
    <div class="faq-window">
      <n-card class="faq-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="faq-info">
            <n-items>
              <n-input title="Название акции" v-bind="$form.input('title')"></n-input>
              <c-textarea title="Описание администратору" v-bind="$form.input('description')"></c-textarea>
              <c-textarea title="Описание пользователю" v-bind="$form.input('userDescription')"></c-textarea>
              <n-input title="Процент комиссии при акции" v-bind="$form.input('percent')"></n-input>
              <n-input title="Минимальная комиссия при акции" v-bind="$form.input('minCommission')"></n-input>
              <n-select title="Тип акции" :data="types" v-bind="$form.input('expirationType', 'select')"></n-select>
              <n-select title="Приоритет" :data="priorities" v-bind="$form.input('priority', 'select')"></n-select>
              <n-input title="Количество дней сколько акция действует после выдачи" v-bind="$form.input('daysCount')"></n-input>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCreatePromo',
  data() {
    return {
      priorities: [
        { value: 'high', title: 'Высокий', },
        { value: 'low', title: 'Низкий', },
      ],
      types: [
        { value: 'afterTransaction', title: 'Одноразовая при выводе', },
        { value: 'onDate', title: 'До истечения времени', },
      ],
    }
  },
  created() {
    this.$form.init({
      title: '',
      description: '',
      userDescription: '',
      percent: '',
      minCommission: '',
      expirationType: {},
      priority: {},
      daysCount: 30,
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
    },
    save() {
      this.$var('load', true)
      const data = {
        title: this.$form.get('title'),
        description: this.$form.get('description'),
        userDescription: this.$form.get('userDescription'),
        percent: this.$form.get('percent'),
        minCommission: this.$form.get('minCommission'),
        expirationType: this.$form.get('expirationType.value'),
        priority: this.$form.get('priority.value'),
        daysCount: this.$form.get('daysCount'),
      }
      $api.promotions.create(data).then(() => {
        this.closeModal()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-create-promo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 100px;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
