<template>
  <div class="c-company-create" :class="{ 'active': show }">
    <div class="company-window">
      <n-card class="company-card">
        <n-loader :loading="$var('load')" />
        <div class="card-top">
          <n-button flat @click="closeModal">X</n-button>
        </div>
        <div>
          <div class="company-info">
            <n-items>
              <n-input title="Название компании" v-bind="$form.input('title')"></n-input>
              <n-input title="Бин" v-bind="$form.input('bin')"></n-input>
              <n-input title="Лимит проценты" v-bind="$form.input('limit')"></n-input>
              <n-input title="День выплаты заработной платы" v-bind="$form.input('salaryDate')"></n-input>
              <n-select :data="salaryTypes" title="Тип выплачиваемой заработной платы" :value.sync="salaryType"></n-select>
              <div class="passwords-place">
                <div class="lk-passwords-place">
                  <n-input
                    id="cabinet-password"
                    title="Пароль для входа в ЛК"
                    type="password"
                    v-bind="$form.input('cabinetPassword')"
                    :class="{ 'error-input': errors.cabinetPassword }"
                  ></n-input>
                  <p v-if="errors.cabinetPassword" class="error-message">{{ errors.cabinetPassword }}</p>

                  <n-input
                    id="cabinet-password-confirm"
                    title="Подтвердите пароль"
                    type="password"
                    v-bind="$form.input('cabinetPasswordConfirm')"
                    :class="{ 'error-input': errors.cabinetPasswordConfirm }"
                  ></n-input>
                  <p v-if="errors.cabinetPasswordConfirm" class="error-message">{{ errors.cabinetPasswordConfirm }}</p>
                </div>

                <div class="integration-passwords-place">
                  <n-input
                    id="integration-password"
                    title="Пароль для 1с интеграции"
                    type="password"
                    v-bind="$form.input('integrationPassword')"
                    :class="{ 'error-input': errors.integrationPassword }"
                  ></n-input>
                  <p v-if="errors.integrationPassword" class="error-message">{{ errors.integrationPassword }}</p>

                  <n-input
                    id="integration-password-confirm"
                    title="Подтвердите пароль"
                    type="password"
                    v-bind="$form.input('integrationPasswordConfirm')"
                    :class="{ 'error-input': errors.integrationPasswordConfirm }"
                  ></n-input>
                  <p v-if="errors.integrationPasswordConfirm" class="error-message">{{ errors.integrationPasswordConfirm }}</p>
                </div>
              </div>
            </n-items>
          </div>
          <div class="button-save">
            <n-button color="primary" @click="save">Сохранить</n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCompanyCreate',
  props: {
    show: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      commissionTypes: [
        { name: 'company', title: 'С компании', value: true, },
        { name: 'user', title: 'С работников', value: false, },
      ],
      salaryTypes: [
        { name: 'netto', title: 'Нетто', value: true, },
        { name: 'brutto', title: 'Брутто (Гросс)', value: false, },
      ],
      commissionType: { name: 'company', title: 'С компании', value: true, },
      salaryType: { name: 'brutto', title: 'Брутто (Гросс)', value: false, },
      limit: 50,
      commissionPercent: 5,
      errors: {
        cabinetPassword: '',
        cabinetPasswordConfirm: '',
        integrationPassword: '',
        integrationPasswordConfirm: '',
      },
    }
  },
  watch: {
    form: {
      handler: 'validatePasswords',
      deep: true,
    },
  },
  created() {
    this.$form.init({
      title: '',
      bin: '',
      limit: '50',
      salaryDate: '10',
      commissionPercent: '5',
      minCommission: '499',
      cabinetPassword: '',
      cabinetPasswordConfirm: '',
      integrationPassword: '',
      integrationPasswordConfirm: '',
    })
  },
  methods: {
    closeModal() {
      this.errors = {
        cabinetPassword: '',
        cabinetPasswordConfirm: '',
        integrationPassword: '',
        integrationPasswordConfirm: '',
      }
      this.$emit('update:show', false)
    },
    save() {
      const passwordsIsValid = this.validatePasswords()
      if (!passwordsIsValid) {
        return
      }

      const data = {
        title: this.$form.get('title'),
        bin: this.$form.get('bin'),
        limit: this.$form.get('limit'),
        salaryDate: this.$form.input('salaryDate').value === '0' ? '10' : this.$form.get('salaryDate'),
        selfCommission: this.commissionType.value,
        isSalaryNetto: this.salaryType.value,
        commissionPercent: this.$form.get('commissionPercent'),
        minCommission: this.$form.get('minCommission'),
        cabinetPassword: this.$form.get('cabinetPassword'),
        cabinetPasswordConfirm: this.$form.get('cabinetPasswordConfirm'),
        integrationPassword: this.$form.get('integrationPassword'),
        integrationPasswordConfirm: this.$form.get('integrationPasswordConfirm'),
      }
      this.$var('load', true)
      $api.companies.create(data)
        .then(() => {
          this.closeModal()
        })
        .catch((response) => {
          console.log(response);
          const nameError = Object.keys(response)[0] + ' ' + response.title
          $app.ui.notify(nameError, 'danger');
        })
        .finally(() => {
          this.$var('load', false)
        })
    },
    validatePasswords() {
      this.errors = {
        cabinetPassword: '',
        cabinetPasswordConfirm: '',
        integrationPassword: '',
        integrationPasswordConfirm: '',
      };

      const passwordRules = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,}$/;

      if (!passwordRules.test(this.$form.get('cabinetPassword'))) {
        this.errors.cabinetPasswordConfirm = 'Пароль должен содержать минимум 6 символов, включая латинские буквы, цифры и специальные символы'
      }

      if (this.$form.get('cabinetPassword') !== this.$form.get('cabinetPasswordConfirm')) {
        this.errors.cabinetPasswordConfirm = 'Пароли не совпадают'
      }

      if (!passwordRules.test(this.$form.get('integrationPassword'))) {
        this.errors.integrationPasswordConfirm = 'Пароль должен содержать минимум 6 символов, включая латинские буквы, цифры и специальные символы'
      }

      if (this.$form.get('integrationPassword') !== this.$form.get('integrationPasswordConfirm')) {
        this.errors.integrationPasswordConfirm = 'Пароли не совпадают'
      }

      return !Object.values(this.errors).some((error) => error);
    },
  },
}
</script>

<style lang="scss" scoped>
.c-company-create {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);

  .company-card {
    min-width: 700px;
    min-height: 300px;
    max-width: 80vw;
    display: flex;

    &::v-deep .content {
      max-height: 100vh;
    }
  }

  .button-save {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
.active {
  display: flex;
}
.passwords-place {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
}

.integration-passwords-place {
  width: 48%;
}

.lk-passwords-place {
  width: 48%;
}
.error-input {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 15px;
  margin-top: 5px;
}
</style>
