<template>
    <div class="c-create-filial">
      <div class="filial-window">
        <n-card class="filial-card">
          <n-loader :loading="$var('load')" />
          <div class="card-top">
            <n-button flat @click="closeModal">X</n-button>
          </div>
          <div>
            <div class="filial-info">
              <n-items>
                <n-input title="Название филиала" v-bind="$form.input('title')" ></n-input>
                <n-input title="Начало Раб. дня" v-bind="$form.input('workStart')" type="time"></n-input>
                <n-input title="Конец Раб. дня" v-bind="$form.input('workEnd')" type="time"></n-input>
                <n-input title="Разрешенное время опоздания" v-bind="$form.input('toleranceTime')"></n-input>
              </n-items>
              <div class="map-container">
                <span>Местонахождение</span>
                <c-filial-create-edit-google-map @polygonSelected="handlePolygonSelection"/>
              </div>
            </div>
            <div class="button-save">
              <n-button color="primary" @click="save">Сохранить</n-button>
            </div>
          </div>
        </n-card>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CCreateFilial',
    props: {
      company: {
        type: Object,
        required: true,
      },
    },
    data() {
      return ({
        polygonCoordinates: [],
      })
    },
    created() {
      this.$form.init({
        title: '',
        workStart: '',
        workEnd: '',
        toleranceTime: 0,
      })
      this.$form.rules({
        title: [ 'required', ],
        workStart: [ 'required', ],
        workEnd: [ 'required', ],
        toleranceTime: [ 'required' ],
      })
    },
    methods: {
      closeModal() {
        this.$emit('update:show', false)
      },
      save() {
        if (this.$form.check()) {
          this.$var('load', true)
          $api.filials.create(this.$props.company.id, {...this.$form.get(),
            area: this.polygonCoordinates,
          })
          .then()
          .finally(() => {
            this.closeModal()
            this.$var('load', false)
          })
        }
      },
      handlePolygonSelection(polygonCoordinates) {
        this.polygonCoordinates = polygonCoordinates;
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .c-create-filial {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .2);
  
    .button-save {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: right;
    }
    .card-top {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    .ymap-container {
      margin-top: 20px;
      height: 600px;
      border-radius: 3px;
      border: 1px solid var(--border-color);
      box-sizing: content-box;
    }

    .map-container {
      margin-top: 16px;
    }
  }
  </style>
