<template>
    <div>
        <section
            class="google-map"
            ref="googleMap"
        ></section>
        <template v-if="Boolean(this.maps) && Boolean(this.drawingManager)">
        <slot
            :maps="maps"
        ></slot>
    </template>
    <n-button @click="deletePolygon">Удалить полигон</n-button>
</div>
</template>


<script>
    import { Loader } from "@googlemaps/js-api-loader";

    export default {
        name: "CFilialCreateEditGoogleMap",
        props: {
            initialPolygon: {
                require: false,
                type: Array
            }
        },
        data() {
            return ({
                maps: null,
                drawingManager: null,
                currentPolygon: {
                    coordinates: [],
                    overlay: null
                },
                mapConfig: {
                    center: { lat: 43.2380, lng: 76.8829 },
                    zoom: 11,
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                },
            })
        },
        computed: {
            googleMapApiKey() { return $env('googleMapsApiKey') }
        },

        async created() {
            await this.initMap()

            this.$props.initialPolygon && this.drawInitialPolygon();
        },
        methods: {
            async initMap() {
                const loader = new Loader({
                    apiKey: this.googleMapApiKey,
                })

                const { Map } = await loader.importLibrary('maps')
                this.maps = new Map(this.$refs.googleMap, this.mapConfig)
                const { DrawingManager } = await google.maps.importLibrary("drawing")
                this.drawingManager = new DrawingManager({
                    drawingMode: google.maps.drawing.OverlayType.POLYGON,
                    drawingControl: !this.currentPolygon.overlay,
                    drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.POLYGON,
                    ],
                    },
                });

                this.drawingManager.setMap(this.maps)

                google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
                    this.currentPolygon.coordinates = polygon.getPath().getArray()
                        .map((point) => {
                            return [point.lng(), point.lat()]
                        })
                    this.currentPolygon.coordinates.push(this.currentPolygon.coordinates[0])
                    this.currentPolygon.overlay = polygon
                    this.hideDrawingControls(true)
                    this.$emit("polygonSelected", this.currentPolygon.coordinates)
                });
            },
            deletePolygon() {
                if (this.currentPolygon.overlay) {
                    this.currentPolygon.overlay.setMap(null)
                    this.currentPolygon.coordinates = []
                }
                this.hideDrawingControls(false)
            },
            hideDrawingControls(hide) {
                this.drawingManager.setDrawingMode(hide ? null : google.maps.drawing.OverlayType.POLYGON)
                this.drawingManager.setOptions({ drawingControl: !hide })
            },
            drawInitialPolygon() {
                const polygon = new google.maps.Polygon({
                    paths: this.$props.initialPolygon.map(vertex => ({lng: vertex[0], lat: vertex[1]})),
                });
                this.currentPolygon.coordinates = this.$props.initialPolygon
                this.currentPolygon.overlay = polygon
                this.zoomToPolygon(this.$props.initialPolygon)
                polygon.setMap(this.maps)
                this.hideDrawingControls(true)
            },
            zoomToPolygon(polygon) {
                const bounds = new google.maps.LatLngBounds();
                polygon.forEach((point) => {
                    bounds.extend(new google.maps.LatLng(point[1], point[0]));
                });
                this.maps.fitBounds(bounds);
            },

        },
    }
</script>

<style scoped>
.google-map {
    width: 100%;
    height: 500px;
    border-radius: 3px;
    border: 1px solid var(--border-color);
    margin: 16px 0px;
}
</style>